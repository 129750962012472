import { PluginPipeline } from '@ads/plugin-pipeline';
import { loggerServicePlugin } from '@/features/core/logger';
import { ordersServicePlugin, OrderLocalStatus } from '@/features/orders';
import { errorPlugin } from '@/features/core/errors';
import router from '@/features/core/router';
import {
  AppCrashPlugin,
  EmitEventBusStatusChangePlugin,
  UpdateLocalStatusPlugin,
  TrackOrderEventPlugin,
} from '@/features/orders/plugins';
import { OrderEventNames } from '@/features/orders';
import { useOnlineStatus } from '@/features/orders/composables';
import type { OrderRestagingPluginDto } from '../types';
import { ResetOrderCheckInPlugin, SaveRestagedOrderPlugin } from '../plugins';

export function orderRestagingPluginRegister(): PluginPipeline<OrderRestagingPluginDto> {
  const orderRestagingPluginPipeline =
    new PluginPipeline<OrderRestagingPluginDto>();

  orderRestagingPluginPipeline.registerPlugins([
    new EmitEventBusStatusChangePlugin('handover-restaged'),
    new TrackOrderEventPlugin(
      ordersServicePlugin.get(),
      loggerServicePlugin.get(),
      OrderEventNames.restaging_completed,
      {
        allowMultipleTracking: true,
        skipSaving: true,
      },
    ),
    new ResetOrderCheckInPlugin(),
    new UpdateLocalStatusPlugin(
      loggerServicePlugin.get(),
      OrderLocalStatus.HandoverRestaged,
    ),
    new AppCrashPlugin(),
    new SaveRestagedOrderPlugin(
      ordersServicePlugin.get(),
      loggerServicePlugin.get(),
      errorPlugin.get(),
      router.get(),
      useOnlineStatus().isOnline,
    ),
  ]);

  return orderRestagingPluginPipeline;
}
