import { useRouter } from 'vue-router';
import type { OrderBag } from '@/features/orders/types';
import type { UseBagsStorage } from '@/features/handover-preparation/types';
import { sessionStoragePlugin } from '@/features/session-storage';

export function useBagsStorage(): UseBagsStorage {
  const router = useRouter();

  const getStorageKey = (storedDataQuery: string) => {
    const currentRoute = router.currentRoute.value;
    const storageKey = `${String(currentRoute.name)}/${String(
      currentRoute.params.id,
    )}:${storedDataQuery}`;
    return storageKey;
  };

  const bagQuantityStorageKey = getStorageKey('bagsQuantity');

  const saveBagsQuantityToStorage = (bagsArray: OrderBag[] | undefined) => {
    if (bagsArray) {
      const bagsQuantityData = bagsArray.map(({ sku, quantity }) => {
        return { sku, quantity };
      });
      sessionStoragePlugin
        .get()
        .setItem(bagQuantityStorageKey, bagsQuantityData);
    }
  };

  const getBagsQuantityFromStorage = () => {
    return sessionStoragePlugin
      .get()
      .getItem<OrderBag[] | null>(bagQuantityStorageKey);
  };

  return {
    saveBagsQuantityToStorage,
    getBagsQuantityFromStorage,
  };
}
