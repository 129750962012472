import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/images/complete-picking-bags@2.png'


const _hoisted_1 = { class: "note__body" }
const _hoisted_2 = { class: "note__header" }
const _hoisted_3 = { class: "footer fixed-footer-content__footer" }
const _hoisted_4 = { class: "ut-font_fix" }

import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { OrderEventNames, ordersServicePlugin } from '@/features/orders';
import { AppHeader, BackButton, Bags, Button } from '@/features/ui';
import ScreenLoading from '@/features/ui/components/ScreenLoading.vue'; // NOTE:  Failed to resolve component, when importing via barrel
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';
import { useOrder as usePickingOrder } from '@/features/picking';
import { useOrder, useBagsStorage } from '../composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'AssigningBags',
  props: {
    id: {}
  },
  setup(__props: any) {

const props = __props;

const scrollTo = ref<null | HTMLElement>(null);
const { bags, loading, order, loadOrder, setBagQuantity } = useOrder();

const { redirectIfOrderNotPicking } = usePickingOrder();

const { saveBagsQuantityToStorage, getBagsQuantityFromStorage } =
  useBagsStorage();
const processing = ref(false);
const titleRef = ref(Element);
const router = useRouter();
const { startTracking, stopTracking } = usePerformanceTracker();

onMounted(async () => {
  processing.value = true;
  await getBags();
  processing.value = false;
  stopTracking(`review-order-to-order-bags-${props.id}`);
  await redirectIfOrderNotPicking(order.value);
  if (order.value) {
    await ordersServicePlugin
      .get()
      .trackEvent(order.value, OrderEventNames.staging_started);
  }
});

const setOrderBagsQuantityFromStorage = () => {
  const storedBags = getBagsQuantityFromStorage();
  if (storedBags && Array.isArray(storedBags)) {
    storedBags.forEach((storedBag) => {
      const bag = order.value?.bags.find(
        (bagItem) => bagItem.sku === storedBag.sku,
      );

      if (bag) {
        setBagQuantity(bag, storedBag.quantity);
      }
    });
  }
};

const initWatcher = () =>
  watch(
    bags,
    () => {
      if (order.value) saveBagsQuantityToStorage(bags.value);
    },
    { deep: true },
  );

const getBags = async () => {
  await loadOrder(props.id);
  setOrderBagsQuantityFromStorage();
  initWatcher();
};

const goNext = async () => {
  processing.value = true;

  if (order.value) {
    await ordersServicePlugin.get().saveOrder(order.value);
  }

  startTracking(
    `review-orders-to-picking-labels-${props.id}`,
    PerformanceThresholdEnum.ROUTE_CHANGE,
  );

  await router.push({
    name: 'picking-labels',
    params: {
      id: props.id,
    },
  });
};

const isBagFocused = ref(false);
const focusInHandler = () => {
  isBagFocused.value = true;
  scrollTo.value?.scrollIntoView();
};
const focusOutHandler = () => {
  isBagFocused.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(order))
      ? (_openBlock(), _createBlock(_unref(AppHeader), {
          key: 0,
          class: "header"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(BackButton), {
              to: { name: 'review-order', params: { id: _ctx.id } }
            }, null, 8, ["to"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_unref(loading) || processing.value)
      ? (_openBlock(), _createBlock(ScreenLoading, {
          key: 1,
          title: _ctx.$t('pages.review-assigning-bags.loading-title'),
          blur: ""
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([
      'note fixed-footer-content fixed-footer-content--extra-padding',
      { minified: _unref(bags)?.length === 1 },
    ])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "note__image" }, [
            _createElementVNode("img", {
              alt: "",
              class: "ut-responsive-img",
              "data-e2e": "bags-screen-image",
              height: "176",
              src: _imports_0,
              width: "156"
            })
          ], -1)),
          _createElementVNode("h1", {
            ref_key: "titleRef",
            ref: titleRef,
            class: "note__title",
            "data-e2e": "bags-screen-title"
          }, _toDisplayString(_ctx.$t('pages.review-assigning-bags.title.text')), 513)
        ]),
        (_unref(bags))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              ref_key: "scrollTo",
              ref: scrollTo,
              class: "bags-list"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(bags), (item) => {
                return (_openBlock(), _createBlock(_unref(Bags), {
                  key: item.sku,
                  name: item.name,
                  quantity: item.quantity,
                  sku: item.sku,
                  onFocusIn: focusInHandler,
                  onFocusOut: focusOutHandler,
                  "onUpdate:quantity": ($event: any) => (_unref(setBagQuantity)(item, $event))
                }, null, 8, ["name", "quantity", "sku", "onUpdate:quantity"]))
              }), 128))
            ], 512))
          : _createCommentVNode("", true)
      ])
    ], 2),
    _withDirectives(_createElementVNode("footer", _hoisted_3, [
      _createVNode(_unref(Button), {
        disabled: processing.value,
        btnOutline: "",
        class: "column--full",
        "data-e2e": "confirm-button",
        onClick: goNext
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('pages.review-assigning-bags.confirm-amount.text')), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ], 512), [
      [_vShow, !isBagFocused.value]
    ])
  ], 64))
}
}

})