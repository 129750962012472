import { BooleanNumber } from '@/features/core/storage';
import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { OrderRestagingPluginDto } from '../types';

export class ResetOrderCheckInPlugin
  implements PipelinePlugin<OrderRestagingPluginDto>
{
  public execute(dto: OrderRestagingPluginDto): OrderRestagingPluginDto {
    if (!dto.order.checkIn) {
      return dto;
    }

    dto.order.checkIn = {
      isCheckedIn: BooleanNumber.False,
      isAcknowledged: false,
    };

    return dto;
  }
}
